<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Email Benachrichtigungen
    </div>

    <div class="xColumn">
      <strong style="font-size: 16px">Neuer Mitarbeiter wurde angelegt</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForNewEmployeeNotifications"
          v-bind:key="'emailsForNewEmployeeNotifications' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>
    <!-- v-on:click="saveEmailForNewEmployeeNotification" -->

    <div class="xColumn">
      <strong style="font-size: 16px">Arbeitsvertrag bei einem Mitarbeiter wurde beendet</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForEmployeeFiredNotifications"
          v-bind:key="'emailsForEmployeeFiredNotifications' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="xColumn">
      <strong style="font-size: 16px">Monatsabschluss wurde abgeschlossen</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForMonthlyFinancialStatementClosed"
          v-bind:key="'emailsForMonthlyFinancialStatementClosed' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="xColumn">
      <strong style="font-size: 16px">Monatsabschluss wurde nochmal geöffnet</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForMonthlyFinancialStatementReopened"
          v-bind:key="'emailsForMonthlyFinancialStatementReopened' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="xColumn mt-5" v-if="externalSoftware == 2">
      <strong style="font-size: 16px">Export zu Sotec kann nicht erstellt werden</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForSotecProblem"
          v-bind:key="'emailsForSotecProblem' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="xColumn mt-5" v-if="externalSoftware == 2">
      <strong style="font-size: 16px">Vorab Überprüfung von Sotec-Exportdaten</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForSotecPrecheck"
          v-bind:key="'emailsForSotecPrecheck' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>


    <div class="xColumn mt-5" v-if="externalSoftware == 2">
      <strong style="font-size: 16px">Spezialisierter Export (Company 136,137)</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Emailadresse</th>
          <th class="documentsTh" style="text-align: center">Löschen</th>
        </tr>
        <tr
          v-for="listing in emailsForSotecExportChecklists"
          v-bind:key="'emailsForSotecExportChecklists' + listing.id"
        >
          <td class="documentsTd">
            <strong>{{ listing.email }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Eintrag löschen'"
              icon="fa-trash"
              v-on:click="deleteEmailForNewEmployeeNotification(listing.id)"
            />
          </td>
        </tr>
      </table>
    </div>

    


  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "NotificationOverview",
  data() {
    return {
      emailsForNewEmployeeNotifications: [],
      emailsForEmployeeFiredNotifications: [],
      emailsForMonthlyFinancialStatementClosed: [],
      emailsForMonthlyFinancialStatementReopened: [],
      emailsForSotecProblem: [],
      emailsForSotecPrecheck: [],
      emailsForSotecExportChecklists: [],
    };
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
      "externalServicesMap",
      "companySettings",
      "selectedStores",
      "externalSoftware",
    ]),
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format("YYYY-MM-DD");
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });
      state.buttons.push({
        type: "block green",
        icon: "fa-plus",
        click: () => {
          view.openNewEntryPopup();
        },
        tooltip: "Eine neue Emailadresse eintragen",
      });
      return state;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.emailsForNewEmployeeNotifications = [];
      view.emailsForEmployeeFiredNotifications = [];
      view.emailsForMonthlyFinancialStatementClosed = [];
      view.emailsForMonthlyFinancialStatementReopened = [];
      view.emailsForSotecProblem = [];
      view.emailsForSotecPrecheck = [];
      view.emailsForSotecExportChecklists = [];
      this.loadListForNewEmployeeNotification();
    },

    loadListForNewEmployeeNotification: function () {
      let view = this;
      let url = "/api/sec/notification/email/list";
      const params = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          view.emailsForNewEmployeeNotifications = [];
          view.emailsForEmployeeFiredNotifications = [];
          view.emailsForMonthlyFinancialStatementClosed = [];
          view.emailsForMonthlyFinancialStatementReopened = [];
          view.emailsForSotecProblem = [];
          view.emailsForSotecPrecheck = [];
          view.emailsForSotecExportChecklists = [];
          for (let i in response.data.data) {
            let o = response.data.data[i];
            if (o.notificationEventId == 1) {
              view.emailsForNewEmployeeNotifications.push(o);
            } else if (o.notificationEventId == 2) {
              view.emailsForMonthlyFinancialStatementClosed.push(o);
            } else if (o.notificationEventId == 3) {
              view.emailsForMonthlyFinancialStatementReopened.push(o);
            } else if (o.notificationEventId == 4) {
              view.emailsForSotecProblem.push(o);
            }else if(o.notificationEventId == 5) {
              view.emailsForEmployeeFiredNotifications.push(o);
            }else if(o.notificationEventId == 6) {
              view.emailsForSotecPrecheck.push(o);
            }else if(o.notificationEventId == 7) {
              view.emailsForSotecExportChecklists.push(o);
            }
          }
        })
        .catch(function (error) {});
    },
    saveNewEmailListing: function (notificationEventId, newEmailForNotification) {
      let view = this;
      let url = "/api/sec/notification/email/list";
      const params = {
        companyId: this.$store.state.companyId,
      };
      let payload = {
        email: newEmailForNotification,
        notificationEventId: notificationEventId,
      };
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Information", "Email Adresse wurde hinzugefügt");
          view.loadListForNewEmployeeNotification();
        })
        .catch(function (error) {
          view.$helpers.error("Information", "Fehler beim Hinzufügen der Email Adresse");
        });
    },
    deleteEmailForNewEmployeeNotification: function (id) {
      let view = this;
      let url = "/api/sec/notification/email/newemployeeList";
      const params = {
        companyId: this.$store.state.companyId,
      };
      let payload = {
        id: id,
      };
      this.axios({
        method: "delete",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Information", "Email Adresse wurde gelöscht");
          view.loadListForNewEmployeeNotification();
        })
        .catch(function (error) {
          view.$helpers.error("Information", "Fehler beim Löschen der Email Adresse");
        });
    },

    openNewEntryPopup() {
      let view = this;
      let notificationItems = [];
      let selectedItem = {};
      let selectedItems = [];

      let notificationTypes = [];
      notificationTypes.push({
        id: 1,
        label_de: "Neuer Mitarbeiter wurde angelegt",
      });
      notificationTypes.push({
        id: 5,
        label_de: "Arbeitsvertrag wurde beendet",
      });
      notificationTypes.push({
        id: 2,
        label_de: "Monatsabschluss wurde abgeschlossen",
      });
      notificationTypes.push({
        id: 3,
        label_de: "Monatsabschluss wurde nochmal geöffnet",
      });
      notificationTypes.push({
        id: 4,
        label_de: "Es gibt ein Problem mit dem Sotec Export",
      });
      notificationTypes.push({
        id: 6,
        label_de: "Vorab Überprüfung von Sotec-Exportdaten",
      });
      notificationTypes.push({
        id: 7,
        label_de: "Personalisierte Export Checklisten (Company 136,137)",
      });

      notificationTypes.forEach((t) => {
        t.label = t.label_de;
        t.value = t.id;
        notificationItems.push(t);
        if (1 == t.id) {
          selectedItem = t;
          selectedItems.push(selectedItem);
        }
      });
      let dataEntries = [
        {
          label: "E-Mail Adresse: ",
          key: "email",
          hidden: false,
          type: "text",
          value: "",
          length: 128,
          styles: { width: "500px" },
        },
        {
          label: "Benachrichtigungs Typ",
          key: "notification_type",
          hidden: false,
          type: "dropdownsearch",
          value: null,
          selectMultiple: false,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: notificationItems,
            selectMultiple: false,
            selectedItems: selectedItems,
            selectedItem: selectedItem,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Typ auswählen",
          },
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function (x) {
            view.saveNewEmailListing(x.notification_type, x.email);
          },
        },
        header: "Emailadresse eintragen",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.xColumn {
  width: calc(33% - 30px);
  text-align: left;
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
}
</style>
